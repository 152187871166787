.hover-cursor:hover {
  cursor: pointer !important;
  scroll-behavior: smooth;
}

* {
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: auto;
  /* scrollbar-width: 5px !important; */
  /* Retains default colors */
}

*::-webkit-scrollbar {
  width: 8px !important;
  /* Width of the scrollbar */
  height: 8px;
  /* Height of the scrollbar for horizontal scroll */
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* Rounded corners for the scrollbar thumb */
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* Rounded corners for the scrollbar track */
}